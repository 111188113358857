<template>
	<modal class="NoxModalAnalysis" name="NoxModalAnalysis" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'deleteAnalysis'">Удаление анализа рынка</span>
				</div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div v-if="noxType == 'deleteAnalysis'">Вы действительно хотите удалить анализ рынка?</div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common red" @click="axios" v-if="noxType == 'deleteAnalysis'" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxIsLoading = false;
				this.noxIsError = false;
			},
			resetAlerts: function() {
				this.noxAlert = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать id анализа рынка.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный id анализа рынка.'; }
				else if (i == 3) { this.noxTemp = 'Id анализа рынка не найден в системе.'; }
				else             { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (_this.noxType == 'deleteAnalysis') {
						config.url = '/v2/admin/account/analysis';
						config.data = { id: _this.noxId };
						config.method = 'delete';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.noxType == 'deleteAnalysis') {
									_this.$parent.noxAnalysisData.splice(_this.noxIndex, 1);
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (_this.noxType == 'deleteAnalysis') {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (_this.noxType == 'deleteAnalysis') {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(3); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalAnalysis');
			}
		}
	}
</script>
